.select {
  $root: &;
  position: relative;

  &--active {
    z-index: $indexFlyout;
  }

  &__label {
    display: block;
    position: absolute;
    top: 8px;
    left: 13px;
    transition: transform .15s ease-out;
    color: $selectLabelColor;
    font-size: $inputLabelFontSize;
    z-index: 1;

    &--only-mobile {
      @media (min-width: $screen-tablet-portrait) {
        display: none;
      }
    }

    &--as-placeholder {
      pointer-events: none;
      transform: translate(0, 8px);
      padding-bottom: 0;
      color: $inputPlaceholderColor;
      font-size: $inputFieldFontSizeMobile;

      @media (min-width: $screen-tablet-portrait) {
        font-size: $inputFieldFontSizeDesktop;
      }
    }
  }

  &__wrapper {
    width: 100%;
    height: 45px;
    border: 1px solid $inputBorderColor;
    background: $inputBackgroundColor;
    position: relative;
    transition: border .1s, box-shadow .3s;
    cursor: pointer;
    border-radius: $selectBorderRadius;

    &:hover {
      border-color: $inputFocusBorderColor;
      background: $inputFocusBackgroundColor;
    }

    @include iconFont(arrowRight) {
      position: absolute;
      top: 50%;
      right: $selectArrowRight;
      transform: translateY(-50%) rotate(90deg);
      font-size: $selectArrowFontSize;
      font-weight: $selectArrowFontWeight;
      color: $selectIconColor;
      z-index: 1;

      #{$root}--formless & {
        font-size: $selectArrowFormlessFontSize;
      }
    }

    &--error {
      border: 1px solid $inputErrorBorderColor;
    }

    &--success {
      border: 1px solid $inputSuccessBorderColor;
    }

    &--disabled {
      background: $backgroundGrayColor;
      cursor: not-allowed;

      &:hover {
        border: 1px solid $borderDefaultColor;
      }

      &::before {
        display: none;
      }
    }

    &--active {
      &::before {
        transform: translateY(-50%) rotate(270deg);
      }
    }
  }

  &__field {
    opacity: 0;
    appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: $inputFieldFontSizeMobile;
    padding: 10px 0 0 10px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $inputFieldFontSizeDesktop;
    }

    &--hidden:not(:focus) {
      @include visually-hidden();
    }

    &::-ms-expand {
      display: none;
    }

    &:focus {
      opacity: 1;
    }
  }

  &__error {
    @include fontSize($baseFontSize);
    color: $textErrorColor;
    display: block;
    margin-top: 8px;
  }

  &__custom-dropdown {
    position: absolute;
    top: 100%;
    // add 2px because parent element has border (1px on both sides);
    width: calc(100% + 2px);
    left: -1px;
    overflow: hidden;
    transition: max-height .2s;
    overflow-y: scroll;
    background: $inputBackgroundColor;
    max-height: 320px;
    z-index: 11;

    &--expand-top {
      top: auto;
      bottom: 100%;
    }

    &--visible {
      border: 1px solid $inputFocusBorderColor;
      box-shadow: $inputShadow;
      overflow-y: auto;
    }
  }

  &__selected-value,
  &__custom-option {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 8px 8px 7px;
    position: relative;
    font-size: $inputFieldFontSizeMobile;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $inputFieldFontSizeDesktop;
    }

    &--align-right {
      justify-content: flex-end;
    }
  }

  &__selected-value {
    height: 100%;
    padding: $selectValuePadding;
    color: $selectInactiveColor;
    min-width: 50px;

    @media (min-width: $screen-tablet-landscape) {
      min-width: auto;
    }

    #{$root}__wrapper--active & {
      color: $selectActiveColor;
    }

    .paging__select & {
      > span {
        margin: 0 auto;

        @media (min-width: $screen-tablet-landscape) {
          margin: 0 0 2px;
        }
      }
    }
  }

  &__custom-option {
    height: 100%;
    background: $selectCustomOptionBackground;
    min-height: 32px;
    color: $selectTextColor;

    &--selected {
      color: $selectCustomOptionSelectedColor;
      font-weight: $selectCustomOptionSelectedFontWeight;
    }

    &:hover {
      @media (min-width: $screen-laptop) {
        color: $selectCustomOptionHoverColor;
        background: $selectCustomOptionHoverBackground;
      }
    }

    &:not(:last-child) {
      border-bottom: $selectCustomOptionBorderBottom;
    }
  }

  &__selected-value-image,
  &__option-value-image {
    max-width: 26px;
    display: block;
    height: auto;
    margin-right: 15px;
  }

  // usage outside of forms
  &--formless {
    margin: 0 -8px;

    #{$root}__wrapper {
      border: none;
      background: transparent;
      padding-right: 16px;
      border-radius: 0;

      @media (min-width: $screen-tablet-landscape) {
        padding-right: 24px;
      }

      #{$root}__selected-value {
        padding: 0 16px 0 0;

        @media (min-width: $screen-mobile) {
          padding: 0 16px;
        }
      }

      &--active.select__wrapper--custom {
        background: $selectBackground;
        color: $selectActiveColor;
        box-shadow: $selectCustomShadow;

        &::before {
          z-index: 1;
          color: $selectActiveColor;
        }

        #{$root}__selected-value {
          z-index: 1;
        }
      }

      &--disabled {
        padding: 0;
        margin-right: -8px;
      }
    }

    #{$root}__custom-dropdown {
      border: 0;
      top: 40px;
      left: 0;
      width: 100%;
      box-shadow: $selectCustomShadow;
    }

    #{$root}__custom-options-wrapper {
      background: $selectBackground;
      padding: $selectCustomOptionsWrapperPadding;
    }

    #{$root}__custom-option {
      background: transparent;
      border: 0;
      padding: 4px 0;
      color: $selectTextColor;
    }

    #{$root}__field {
      padding: 4px;
    }
  }

  &--hide-placeholder {
    #{$root}__label {
      display: none;
    }

    #{$root}__selected-value {
      padding: $selectValuePadding;
    }
  }
}
