.input {
  display: block;
  position: relative;

  &__wrapper {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid $inputBorderColor;
    transition: border .1s, box-shadow .3s;
    background: $inputBackgroundColor;
    position: relative;

    &--textarea.input__wrapper {
      height: 110px;

      .input__label + & {
        padding: 20px 0 3px 12px;
      }

      .input__label--as-placeholder + &,
      .input__label--hide-label + & {
        padding: 7px 11px;
      }
    }

    &--alternative {
      background-color: $whiteColor;
      border: 1px solid $grayLightColor;
      border-radius: 4px;
      width: 99.99%;
      height: 45px;
      margin: 0;
      padding: 0 0 0 10px;
      color: $grayMediumColor;
      font-size: $fontSizeXMedium;
      font-family: Arial, sans-serif;

      > .input__field {
        padding: 0;
      }

      &--error {
        border: 1px solid $inputErrorBorderColor;

        &:hover {
          border: 1px solid darken($inputErrorBorderColor, 15);
        }
      }
    }

    &:hover,
    &--focus {
      border-color: $inputFocusBorderColor;
      background: $inputFocusBackgroundColor;
    }

    &--focus {
      box-shadow: $inputShadow;
      border: 1px solid $inputFocusBorderColor;
      background: $inputFocusBackgroundColor;

      input[type="date"].input__field--date {
        padding: 15px 15px 3px 12px;
      }
    }

    &--error {
      border: 1px solid $inputErrorBorderColor;
      background: $inputErrorBackgroundColor;

      &:hover {
        border: 1px solid darken($inputErrorBorderColor, 15);
      }
    }

    &--success {
      border: 1px solid $inputSuccessBorderColor;
      background: $inputSuccessBackgroundColor;

      &:hover {
        border: 1px solid darken($inputSuccessBorderColor, 15);
      }
    }

    &--hidden {
      border: 0 none;
      background: transparent;
      display: none;

      &:hover {
        border: 0 none;
      }
    }

    &--valid {
      @include iconFont('checked', after);

      &::after {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: $inputIconValidColor;
        font-weight: bold;
        padding: 5px;
        font-size: .7em;

        .input__wrapper--textarea & {
          top: 22px;
        }
      }

      & > .input__field {
        padding-right: 40px;
      }
    }

    &--disabled {
      background: $backgroundGrayColor;
      cursor: not-allowed;

      &:hover {
        border: 1px solid $borderDefaultColor;
      }
    }
  }

  &__field {
    width: 100%;
    height: 100%;
    padding: 3px 15px 3px 12px;
    font-size: $inputFieldFontSizeMobile;
    font-weight: $fontWeightRegular;
    color: $inputFieldColor;
    border: none;
    background: none;
    flex: 1;
    resize: none;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $inputFieldFontSizeDesktop;
    }

    .input__label + .input__wrapper & {
      padding: 15px 15px 3px 12px;
    }

    .input__label--as-placeholder + .input__wrapper & {
      padding: 7px 11px;
    }

    .input__label + .input__wrapper &--textarea {
      padding: 0 15px 0 0;
    }

    &:focus {
      outline: 0;
    }

    &[disabled],
    &--disabled {
      opacity: .55;
      cursor: not-allowed;
    }

    &::placeholder {
      font-size: $inputFieldFontSizeDesktop;
      color: $inputPlaceholderColor;
      opacity: 1;
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
  }

  &__button {
    height: 100%;
    flex: 0 0 auto;
    border: none;
    background: $brandPrimaryColor;
    cursor: pointer;
    position: relative;
    text-align: left;
    padding: 0 40px 0 5px;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $textLightColor;
      font-weight: $fontWeightSemiBold;
    }

    @include iconFont(arrowRight, after) {
      font-size: $fontSizeSmall;
    }

    &--icon {
      &_search {
        @include iconFont(search, after) {
          font-size: $fontSizeMedium;
        }
      }

      &_mail {
        @include iconFont(mail, after) {
          font-size: $fontSizeMedium;
        }
      }
    }
  }

  &__button-icon {
    position: relative;
    z-index: 2;
    font-size: 18px;
    color: $textLightColor;
  }

  &__button-text {
    position: relative;
    z-index: 2;
    font-size: $baseFontSize;
    font-weight: $fontWeightRegular;
    color: $textLightColor;
    display: block;
    padding-right: 5px;
  }

  &__error {
    margin-top: 8px;
    position: relative;
    z-index: 5;
    clear: both;
  }

  &__label {
    color: $inputLabelColor;
    font-size: $inputLabelFontSize;
    display: block;
    margin: 0;
    max-height: 50px;
    overflow: hidden;
    transform: translate(0, 0);
    transition: transform .15s ease-out, opacity .05s ease-out;
    position: absolute;
    z-index: 10;
    top: 7px;
    left: 13px;
    line-height: 1.3;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 50px);

    &--only-mobile {
      @media (min-width: $screen-tablet-portrait) {
        display: none;
      }
    }

    &--hide-label {
      display: none;
    }

    &--as-placeholder {
      pointer-events: none;
      opacity: 0;
      transform: translate(3px, 8px);
      padding-bottom: 0;
    }

    &.headline,
    &--static {
      position: relative;
      top: auto;
      left: auto;
      font-size: $inputFieldFontSizeMobile;

      @media (min-width: $screen-tablet-portrait) {
        font-size: $inputFieldFontSizeDesktop;
        margin-bottom: 2px;
      }
    }
  }

  &__hint {
    display: block;
    margin-top: 5px;
    font-size: $fontSizeSmall;
    color: $textGrayColor;

    a {
      color: $textGrayColor;
    }
  }
}
